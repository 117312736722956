@import "https://fonts.googleapis.com/css?family=Montserrat:300,400,700";

.fixed-header-logo{
    position: sticky;
    top: 0;
    width: 90%;
    height: 100%;
    margin: 0 5%;
    padding: 0;
    z-index: 30;
    background-color: #FFFFFF;

}

.logo-header {
    display: flex;
    justify-content: space-around;
    padding: 0;
    width: 100%;
    height: auto;
    font-size: 0.7rem;
    border: none;
}

.logo-carboparc {
    padding: 0;
    margin: calc(0.1vw + 1.7vh);
    width: calc(10vw + 18vh);
    z-index: 15;
}


.filter-tools-bar{
    background-color: #FFFFFF !important;
    padding: 1rem 0 !important;
    justify-content: space-around !important;
}
.filtre-input{
    width: 12rem;
    font-size: 1.2rem;
}
.sensor_type_name{
    padding: 3px;
}
.rwd-table td:before {
    content: attr(data-th) "";
    /*width: 8.5em;*/
    display: inline-block;
}


.rwd-table {
    /*overflow: hidden;*/
    width: 90%;
    margin: 0 5%;
}

.item-tbody {
    border-radius: 0.4em;
    /*overflow: hidden;*/
    width: 100%;
    /*max-width: 100vw;*/
}

.item-container {
    background-color: #ebebeb;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px 0;
    margin: 10px 0;
    justify-content: space-evenly;
}
.closer-sensor {
    background-color: #eeb214;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px 0;
    margin: 10px 0;
    justify-content: space-evenly;
}
.image-container {
    display: flex;
    flex-direction: row;
    padding: 0 0 0 calc(0.7vw + 0.7vh);
    justify-content: space-between;
    justify-items: center;
    text-align: center;
    border: none;
    width: calc(1.2vw + 4.2vh);
    height: calc(1.2vw + 4.2vh);
}
.clickable {
    cursor: pointer;
}
.text-name {
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: space-between;
    justify-items: left;
    text-align: center;
    font-weight: 300;
    font-size: calc(1vw + 1.2vh);
    width: 100%;
    cursor: pointer;
    border: none;
}
.text-distance {
    display: flex;
    padding: 0 calc(0.7vw + 0.7vh) 0 0;
    justify-content: space-between;
    justify-items: left;
    text-align: center;
    font-weight: 700;
    font-size: calc(1vw + 1.5vh);
    width: 25%;
    border: none;
}

.localisation-off {
    width: calc(1.2vw + 3.2vh);
    height: calc(1.2vw + 3.2vh);
    padding: 0 calc(1.2vw + 1.2vh);
    margin: 0;

}
.text-group {
    display: flex;
    flex-direction: column;
    width: 10rem;
}
.td-link{
    color: #000000;
    text-align: left;
    text-decoration: none;
    padding-bottom: 1rem;
}

.td-value{
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 0;
}
.text-label{
    text-align: left;
}
.text-value{
    text-align: right;
}
.checkbox-group {
    position: relative;
    right: 0;
    top: 0;
}

.sticky-logout {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f8f9fa; /* Couleur de fond exemple */
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    text-align: center; /* Centre le bouton dans la div */
    padding: 10px 0; /* Ajoute un peu d'espace au-dessus et en dessous du bouton */
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optionnel: ajoute une légère ombre pour un effet élévé */
}

.logout-button {
    border-radius: calc(1.2vw + 1.2vh);
    width: calc(7.2vw + 3.2vh);
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    text-align: center;
}

.icon-logout {
    /*padding: calc(1.2vw + 1.2vh);*/
    width: calc(4vw + 0.2vh);
    height: calc(4vw + 0.2vh);
    border-radius: 1000px;
    /*padding: calc(0.1vw + 0.6vh);*/
    /*border: solid 2px #eeb214;*/
    /*background-color: #ffffff;*/
    /*color: #3c3935;*/
    /*box-shadow: 10px 5px 5px black;*/
}

.geolocalisation-button {
    font-family: "Montserrat", "sans-serif"; /* Make sure this font is loaded in your project */
    padding: 0.6rem 0;
    margin-bottom: 3%;
    border-radius: 0.5rem;
    border: 0;
    background-color: #EFB001;
    max-width: 15rem;
    width: 50vh;
    font-size: 1.3rem;

    margin-left: auto;
    margin-right: auto
}