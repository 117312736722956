.Consommation-main {
    display: flex;
    flex-direction: column;
}

.texte-budget {
    font-size: calc(1vw + 3vh);
    font-weight: 700;
    margin-top: calc(-2.8vw + 8vh);
    margin-bottom: calc(0.1vw + 1vh);
}

.conso-budget-co2 {
    font-size: calc(2vw + 3vh);
    font-weight: 600;
    margin-bottom: calc(0.1vw + 1vh);
}

.text-consomme {
    font-size: calc(0.8vw + 2.5vh);
    font-weight: 700;
    margin-top: calc(-0.05vw - 3.5vh);
}

.conso-consomme-co2 {
    font-size: calc(2vw + 4vh);
    font-weight: 700;
    margin: calc(0.1vw + 1vh);
}

.depassement {
    color: #a5163f;
}

.poliseMsg{
    font-size: calc(2vw + 3vh) !important;
}