
.header-group {
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
    /*color: #FFFFFF;*/
    background-color: #3c3935;
    font-size: 0.7rem;
    border: none;
}



.link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(1.5vw + 0.5vh);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    color: #FFFFFF;
    width: calc(4.5vw + 1.2vh);
}

.close-icon {

    width: calc(4vw + 0.2vh);
    height: calc(4vw + 0.2vh);
    border-radius: 1000px;
    padding: calc(0.1vw + 0.6vh);
    background-color: #a6163f;
}
.close-iconPopup {

    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: calc(0.1vw + 0.6vh);
    background-color: #a6163f;
}
.info-icon {

    width: calc(4vw + 0.2vh);
    height: calc(4vw + 0.2vh);
    border-radius: 1000px;
    /*padding: calc(0.1vw + 0.6vh);*/
    border: solid 2px #eeb214;
    background-color: #ffffff;
    color: #3c3935;
    /*box-shadow: 10px 5px 5px black;*/
}
.green{
    color: #7EBF2E;
}

.red {
    color: #d10b0b;
}

.strong {
    font-weight: 700;
}

.info-image {

    width: calc(4vw + 1vh);
    height: calc(4vw + 1vh);
}
.popup-info{
    font-family: 'Montserrat'!important;
    color: black;
    font-size: x-large;
    border-radius: 16px;
    position: fixed; /* Positionnement fixe par rapport à la fenêtre du navigateur */
    top: 50%; /* Positionnement à 50% du haut de la fenêtre */
    left: 50%; /* Positionnement à 50% de la gauche de la fenêtre */
    transform: translate(-50%, -50%); /* Centrer la popup */
    width: 80%; /* Largeur de la popup à 80% de la largeur de la fenêtre */
    height: 40%;
    max-width: 600px; /* Une largeur maximale peut être utile pour les grands écrans */
    background-color: white; /* Couleur d'arrière-plan de la popup */
    padding: 20px; /* Espace intérieur autour du contenu de la popup */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Ombre pour un effet en relief */
    z-index: 1000; /* Assure que la popup s'affiche au-dessus des autres éléments */

}
.titre{
    font-family: 'Montserrat'!important;
    font-weight: bold;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

}

.cors{
    text-align: left;
    font-family: 'Montserrat'!important;
    font-size: medium;
    padding: 20px;
    height: 60%;
    overflow-y: scroll;
    font-weight: 500;
}
.btClose{
    position: absolute;
    top:10px;
    background: transparent;
    border: none;
    right: 10px;

}

/* Arrière-plan semi-transparent quand la popup est affichée */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Noir semi-transparent */
    z-index: 999; /* Juste derrière la popup */
}

.header-group h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    /*margin-left: calc(6vw + 0.7vh);*/
    padding: 1% 0;
    border: none;
    border-radius: 0;
    transition: background-color 0.3s;
    width: 100%;
    color: #FFFFFF;
    font-weight: 700;
    font-size: calc(1.2vw + 1.2vh);
}

@media screen and (orientation: landscape) {

    .header-group {
        display: flex;
        padding: 0;
        width: 100%;
        height: auto;
        font-size: 2rem;
        border: none;
    }


    .link {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1% 1%;
        border: none;
        border-radius: 0;
        cursor: pointer;
        transition: background-color 0.3s;
        color: #FFFFFF;
    }

    .header-group h2 {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1% 0;
        border: none;
        border-radius: 0;
        transition: background-color 0.3s;
    }
}

.Header{}
@media screen and (max-width: 260px) {
    .Header {
        /*font-size: 0.5rem;*/
        display: none;
    }
    .header-group {
        font-size: 0.5rem;
    }

    .link {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5% 5%;
        border: none;
        border-radius: 0;
        cursor: pointer;
        transition: background-color 0.3s;
        color: #FFFFFF;
        width: 15%;
    }

    .link:hover {
        background-color: #eeb214;
    }

    .header-group h2 {
        font-size: 1rem;
    }

}

