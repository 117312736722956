table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: #f2f2f2;
}

.valve-details {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.fixed-header {
    position: sticky;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 0;
    padding: 0;
    z-index: 30;
    background-color: #3c3935;
}
.titre {
}
.req-tabs button {
    width: 25%;
    font-size: 1rem;
    font-weight: bold;
    background-color: #F0F0F0;
    color: black;
}

.tabs button {
    width: 25%;
    font-size: 1rem;
    font-weight: bold;
}
.icon-periode {
    filter: invert(1);
    max-width: calc(4vw + 6vh);
}
.icon-onglet {
    max-width: calc(4vw + 6vh);
}

.active {
    background-color: #eeb214 !important;
    color: #FFFFFF !important;
}
.active > img {
    filter: invert(0);
}

.tab-content {
    overflow: hidden;

    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.tool-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.2rem !important;
    border: none;
    background-color: #3c3935;
    color: #FFFFFF;
}
.header2 {
    padding: 10px;
    width: 90%;
    margin: 0;
    font-size: calc(6vw + 1.5vh);
    font-weight: 600;
    line-height: 0.7em;
    /*color: #000000;*/
    /*background-color: #ebebeb;*/
}

.config-group {
    width: 100%;
    height: auto;
    color: #000000;
    background-color: #FFFFFF;

    /*font-size: 1.2rem;*/
    font-weight: bolder;
    text-align: center;
    align-items: center;
}

.config-group button {
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.config-group button:hover {
    background-color: #eeb214;
}

.input-group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /*padding: 0.5rem;*/
}

.input-group input {
    width: 5rem;
    padding: 0.3rem;
}

.icon-label {
    position: relative;
    width: calc(8vw + 25vh);
}

.days-group {
    margin: 2rem 0;
}

.day {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.5rem;
    text-align: left;
}

.label-config {

    margin: 0;
    width: 10rem;
    /*max-width: 60px;*/
}

.input-time-arrosage {
    width: auto;
}

.action-buttons {
    position: fixed;
    bottom: 0;
    justify-content: space-between;
    width: 100%;
}

.form-group {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 1rem 0;
    width: 100%;
}

.action-input-group {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 4rem;
}

.action-input-group input {
    width: 5rem;
    padding: 0.5rem;
}

.action-buttons-group {
    display: flex;
    justify-content: space-around;

    padding: 0;
    width: 100%;
}

.action-button {
    color: white;
    border: none;
    cursor: pointer;
    transition: opacity 0.3s;
    width: 100%;
    font-size: 1.2rem;
    font-weight: bolder;
}

.req-button {
    /*background-color: green;*/
    color: white;
}
.req-button:focus,
.req-button:hover {
    background-color: #eeb214;
}

.req-button:focus > img,
.req-button:hover > img {
    filter: invert(0);
}

.data-container{
    padding: 5%;
    box-shadow: inset 0 0 15px -10px grey;

}

@media screen and (max-width: 260px) {
    .titre {
        display: none;
    }
}

.btnMsg{
    width: 100%;
    background-color: #3c3935;
    color: #FFFFFF;
    -webkit-user-select: none;
    user-select: none;
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.marg{

}
