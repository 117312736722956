.Conseil-main {
    display: flex;
    flex-direction: column;
    padding: 30px;
}
.Fonctionnement {
    border: calc(0.1vw + 0.1vh) solid #e6e5e3;
    box-shadow: calc(0.002vw + 0.002vh) calc(0.002vw + 0.002vh) 2px 1px rgba(0, 0, 255, .2);

    padding: calc(0.8vw + 2.5vh);
    min-height: calc(10vw + 10vh);

    font-size: calc(0.8vw + 2.5vh);
    /*font-weight: 700;*/
    text-align: left;
}
.Ralenti {
    border: calc(0.1vw + 0.1vh) solid #e6e5e3;
    box-shadow: calc(0.002vw + 0.002vh) calc(0.002vw + 0.002vh) 2px 1px rgba(0, 0, 255, .2);

    padding: calc(0.8vw + 2.5vh);
    min-height: calc(10vw + 10vh);

    font-size: calc(0.8vw + 2.5vh);
    /*font-weight: 700;*/
    text-align: left;
}

.minuteur {
    margin-right: 5px;
    height: calc(3vw + 4vh);
}

.conseil{
    color: #EEB214;
    text-align: right;
    font-family: 'Montserrat' !important;
    font-size: calc(0.8vw + 1.8vh);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    background-color: transparent;
    border: none;
    padding-top: 10px;
}
.space{
    margin-top: 10px;
}
.rotate180{
    transform: rotate(180deg);
    margin-top: 8px;
}
.hStyle{
    font-family: "Montserrat";
    font-weight: 800;
}
.Scon{
color: #3C3935 !important;
font-family: "Montserrat"!important;
font-size: 17px !important;
font-style: normal !important;
font-weight: 499 !important;
line-height: normal !important;
}