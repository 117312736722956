/* Assurez-vous que le container prend toute la hauteur disponible */
.container {
    height: 100vh;
}

/* Style pour la carte */
.leaflet-container {
    height: 100%;
    width: 100%;
}

/* Style pour le marqueur de l'utilisateur */
.user-marker-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: blue;
    color: white;
    text-align: center;
    line-height: 20px;
}

/* Style pour le marqueur de valve */
.valve-marker-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: green;
    color: white;
    text-align: center;
    line-height: 20px;
}
