.btnMsg{
    width: 100%;
    background-color: #3c3935;
    color: #FFFFFF;
    -webkit-user-select: none;
    user-select: none;
}
.marginDiv{
    margin: 5px;
    font-size: calc(1vw + 3vh) !important;
    font-weight: 600;
}
.btnSelect{
    margin-top: 20%;
}
.btnMsg{
    text-decoration: none;
}
.CarburanSelct{
    font-size: calc(1vw + 3vh) !important;
    font-weight: 600 !important;
}
.unitcss{
    margin-left: 5px;
    font-size: calc(1vw + 3vh) !important;
    font-weight: 600;
}

.headBandInfo{
width: 100%;
}
.conseil{
    display: flex;
    flex-direction: column;
    padding: 30px;
    text-align: center;
    margin: auto;

}
.close-icon {
    width: calc(4vw + 0.2vh);
    height: calc(4vw + 0.2vh);
    border-radius: 1000px;
    padding: calc(0.1vw + 0.6vh);
    background-color: #a6163f;
    color: #FFFFFF;
    position: absolute;
    top:10px;
    right:10px;
}
.npopup{
    z-index: 0;
    padding: 0px !important;
    border-radius: 20px;
    padding: 30px;
}
.nwspopup{
    padding: 0px !important;
}
.newconseil{
    background-color: #f5f5f500 !important;
}

.backBluer{
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 500;
    right: 0px;
}

.marginup{
    margin-top: -50px !important;   
}
.nuague{
    width: 38% !important;
}
