.consoGazoleParHeure{
    font-size: calc(1vw + 3vh) !important;
    font-weight: 600;
    z-index: 500;

}

.consoGazoleParHeureSelect {
    font-size: calc(1vw + 2.5vh) !important;
    font-weight: 600;
    border: none;
    border-bottom: 1px solid #000000;
    margin-top: 10px !important;
    width: 100%;
}

.consoGazoleParHeureSelectOption {
    text-align: left;
    align-content: end;
    justify-items: left;
    justify-content: left;
    align-items: end;
    font-size: calc(1vw + 2.5vh) !important;
    font-weight: 600;
    border: none;
    border-bottom: 1px solid #000000;
  

}
.Selector{
    font-weight: 600 !important;
    margin-top: 20px;
}
.newcss{
    margin-bottom: -20px;
} 
.btnMsg{
    width: 100%;
    background-color: #3c3935;
    color: #FFFFFF;
    -webkit-user-select: none;
    user-select: none;
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.CarburanSelct{
    font-size: calc(1vw + 3vh) !important;
    font-weight: 600 !important;
}

.headBandInfo{
    width: 100%;
}
.conseil{
    display: flex;
    flex-direction: column;
    padding: 30px;
    text-align: center;
    margin: auto;
    margin-top: 10px;
}

.close-icon {
    width: calc(4vw + 0.2vh);
    height: calc(4vw + 0.2vh);
    border-radius: 1000px;
    padding: calc(0.1vw + 0.6vh);
    background-color: #a6163f;
    color: #FFFFFF;
    position: absolute;
    top:10px;
    right:10px;
}
.npopup{
    z-index: 0;
    padding: 0px !important;
    border-radius: 20px;
    padding: 30px;
    width: 210px;
}
.nwspopup{
    padding: 0px !important;
}
.newconseil{
    background-color: #f5f5f500 !important;
}
.backBluer{
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 500;
    right: 0px;
}