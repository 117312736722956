body {

  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #FFFFFF;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Applique une transition de 3 secondes à tous les éléments */
* {
  transition: all 0.2s ease;
}

button {
  background-color: #3c3935;
  color: #FFFFFF;
  user-select: none;
}

div > label{
  font-size: 1.2rem;
  font-weight: normal;
}

div > h2{
  font-size: 1.2rem;
  font-weight: normal;
}
/*!* Cache tout le contenu principal lorsque l'écran est en mode paysage *!*/
/*@media screen and (orientation: landscape) {*/
/*  .main-content {*/
/*    display: none;*/
/*  }*/
/*}*/


/*.landscape-warning {*/
/*  display: none;*/
/*}*/

/*!* Affiche uniquement le message d'avertissement en mode paysage *!*/
/*@media screen and (orientation: landscape) {*/
/*  .landscape-warning {*/
/*    display: block !important; !* Le div est rendu visible en mode paysage *!*/
/*    position: fixed;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background-color: white;*/
/*    text-align: center;*/
/*    padding-top: 20%;*/
/*    font-size: 24px;*/
/*  }*/
/*}*/