/*[ FONTS ]*/

@font-face {
  font-family: Myriad-Pro-Bold-Condensed;
  src: url('Myriad Pro Bold Condensed/Myriad Pro Bold Condensed.ttf');
}


@font-face {
  font-family: Verdana;
  src: url('Verdana/verdana-4.ttf');
}

@font-face {
  font-family: Verdana-Italic;
  src: url('Verdana/verdana-italic.ttf');
}

@font-face {
  font-family: Verdana-Bold;
  src: url('Verdana/verdana-bold-3.ttf');
}


@font-face {
  font-family: Montserrat-Black;
  src: url('Montserrat/static/Montserrat-Black.ttf');
}

@font-face {
  font-family: Montserrat-BlackItalic;
  src: url('Montserrat/static/Montserrat-BlackItalic.ttf');
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('Montserrat/static/Montserrat-Bold.ttf');
}

@font-face {
  font-family: Montserrat-BoldItalic;
  src: url('Montserrat/static/Montserrat-BoldItalic.ttf');
}

@font-face {
  font-family: Montserrat-ExtraBold;
  src: url('Montserrat/static/Montserrat-ExtraBold.ttf');
}

@font-face {
  font-family: Montserrat-ExtraBoldItalic;
  src: url('Montserrat/static/Montserrat-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: Montserrat-ExtraLight;
  src: url('Montserrat/static/Montserrat-ExtraLight.ttf');
}

@font-face {
  font-family: Montserrat-ExtraLightItalic;
  src: url('Montserrat/static/Montserrat-ExtraLightItalic.ttf');
}

@font-face {
  font-family: Montserrat-Italic;
  src: url('Montserrat/static/Montserrat-Italic.ttf');
}

@font-face {
  font-family: Montserrat-Light;
  src: url('Montserrat/static/Montserrat-Light.ttf');
}

@font-face {
  font-family: Montserrat-LightItalic;
  src: url('Montserrat/static/Montserrat-LightItalic.ttf');
}

@font-face {
  font-family: Montserrat-Medium;
  src: url('Montserrat/static/Montserrat-Medium.ttf');
}

@font-face {
  font-family: Montserrat-MediumItalic;
  src: url('Montserrat/static/Montserrat-MediumItalic.ttf');
}

@font-face {
  font-family: Montserrat;
  src: url('Montserrat/static/Montserrat-Regular.ttf');
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url('Montserrat/static/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: Montserrat-SemiBoldItalic;
  src: url('Montserrat/static/Montserrat-SemiBoldItalic.ttf');
}

@font-face {
  font-family: Montserrat-Thin;
  src: url('Montserrat/static/Montserrat-Thin.ttf');
}

@font-face {
  font-family: Montserrat-ThinItalic;
  src: url('Montserrat/static/Montserrat-ThinItalic.ttf');
}
