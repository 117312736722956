.btnInstall{
  margin-left: 0%;
  padding: 0px;
  z-index: 10000;
  background: white;
  color: #3C3935;
  font-size: 18px;
  font-weight: 700;
  width: fit-content;
  height: fit-content;
  border: solid 0px #3C3935;
  }
  .iconN{
    color:#3C3935 !important;
    position: relative;
    top: 8px;
  }
  
  .backB{
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    z-index: 500;
  }
  .backBNew{
  display: none;
  }
  
  .logoInstallationMsg{
  
    width: 160px;
    height: fit-content;
    margin: 5px;
  }
  
  .closeall{
    display: none;
  }
  
  .close-iconPopup {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    padding: calc(0.1vw + 0.6vh);
    background-color: #a6163f;
    box-shadow: 3px 3px 3px 0px rgba(56, 52, 52, 0.539);
  }
  .closeIn{
    position: absolute;
    top: 5px;
    right: 0px;
    background: transparent;
    border: none;
  }
  
  .popup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
  padding: 15px;
  border-radius: 10px;
  z-index: 10000;
  background: white;
  color: #3C3935;
  font-size: 18px;
  font-weight: 700;
  width: fit-content;
  height: fit-content;
  border: solid 0px #3C3935;
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2);
  }
  .msgInstall{
    white-space: nowrap;
   text-overflow: ellipsis;
  }
  