.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
    z-index: 5;
}
.logo {
    padding: 0;
    margin: 0;
    /*position: fixed;*/
    /*top: 5%;*/
    /*left: 10%;*/
    width: calc(10vw + 25vh);
    z-index: 15;
}

.auth-box {
    /*position: fixed;*/
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 5% 0 1rem 0;

    width: 100%;
    height: 50%;
    background-color: #ffffff;
    color: #000000;
    border-radius: 8px;
    text-align: center;
    z-index: 5;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
}

.bonjour {
    margin: 0;
    padding: 0;
    font-size: 3.5rem;
}
.intituler-connexion {
    /*margin: 0 0 8rem 0;*/
    margin: 0 0 3% 0;
    padding: 0;
    font-size: 1.2rem;
}

.auth-form {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.login-label {
    text-align: left;
    font-family: "Montserrat", sans-serif; /* Make sure this font is loaded in your project */
    color: #000000;
    padding: 15px;
    margin: 1rem 0 0 0;
    border-radius: 50px;
    border: 0;
    width: 70vw;
}
.login-input {
    padding: 10px;
    margin: 0;
    border-radius: 50px;
    border: 0;
    width: 70vw;
}
.forgot-password {
    text-align: center;
    padding: 0;
    margin: 0.2% 0 5% 0;
    border-radius: 5px;
    text-underline: none;
    /*color: #FFFFFF;*/
    opacity: 0.8;
    font-weight: 499;
    font-size: small;
}
.forgot-password:hover {
    opacity: 1;
    transition: 0.3s all;
}
.login-button {
    font-family:"Montserrat", sans-serif; /* Make sure this font is loaded in your project */
    padding: 0.6rem 0;
    margin-bottom: 3%;
    border-radius: 0.5rem;
    border: 0;
    background-color: #3C3935;
    max-width: 15rem;
    width: 50vh;
    font-size: 1rem;
    /*font-weight: bold;*/
    font-weight: 500 !important;
    display: block;
    margin-left: auto;
    margin-right: auto
}
.sso{
    background-color: #EFB001;
}

/* Styles pour les écrans d'ordinateurs */
@media (min-width: 550px) {
    .auth-background {
        padding: 0;
        margin: 0;
        position: fixed;
        top: -60%;
        left: -10%;
        /*height: 100%;*/
        width: 150%;
        color: red;
        /*margin-bottom: 50%;*/
        z-index: 10;
    }
}

.f{
    color: black;
    font-size: medium
}
.pad{
    margin-top: 20px;
}